<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="12">
          <b-form-group
            :class="{ row, error: v$.coupon.name.$errors.length }"
            :label="$t('forms.name')"
            label-for="h-name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input v-model="coupon.name" id="h-name" />
            <div
              class="input-errors"
              v-for="error of v$.coupon.name.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.code.$errors.length }"
            :label="$t('forms.code')"
            label-for="h-code"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input v-model="coupon.code" id="h-code" />
            <div
              class="input-errors"
              v-for="error of v$.coupon.code.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.typeSelect.$errors.length }"
            :label="$t('forms.value')"
            label-for="h-type"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <v-select
              id="h-type"
              v-model="coupon.typeSelect"
              :options="types"
            />
            <div
              class="input-errors"
              v-for="error of v$.coupon.typeSelect.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            class="row"
            :label="$t('forms.resort')"
            label-for="resortSelect"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <v-select
              id="resortSelect"
              v-model="coupon.resortSelect"
              :options="resorts"
            />
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.discountedValue.$errors.length }"
            :label="$t('forms.discount')"
            label-for="h-discount"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input v-model="coupon.discountedValue" id="h-discount" />
            <div
              class="input-errors"
              v-for="error of v$.coupon.discountedValue.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.count.$errors.length }"
            :label="$t('forms.couponCount')"
            label-for="h-couponCount"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-input v-model="coupon.count" id="h-couponCount" type="number" />
            <div
              class="input-errors"
              v-for="error of v$.coupon.count.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.fromDate.$errors.length }"
            :label="$t('forms.fromDate')"
            label-for="h-couponCount"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <datepicker
              id="fromDate"
              v-model="coupon.fromDate"
              class="form-input"
            />
            <div
              class="input-errors"
              v-for="error of v$.coupon.fromDate.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            :class="{ row, error: v$.coupon.toDate.$errors.length }"
            :label="$t('forms.toDate')"
            label-for="h-couponCount"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <datepicker
              id="toDate"
              v-model="coupon.toDate"
              class="form-input"
            />
            <div
              class="input-errors"
              v-for="error of v$.coupon.toDate.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group
            class="row"
            :label="$t('forms.status')"
            label-for="h-enabled"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-checkbox v-model="coupon.onOff" name="enabled" switch />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
} from "bootstrap-vue-3";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import vSelect from "vue-select";
import Datepicker from "vue3-datepicker";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    vSelect,
    Datepicker,
  },
  props: {
    couponObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      coupon: {
        name: { required },
        code: { required },
        typeSelect: { required },
        discountedValue: { required },
        fromDate: { required },
        toDate: { required },
        count: { required },
      },
    };
  },
  created() {
    this.getEnumList("coupon_type", this.types);
    this.getResortList(this.resorts)
  },
  data() {
    return {
      coupon: this.couponObject,
      types: [],
      resorts: [],
      resortSelect: null
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          name: this.coupon.name,
          code: this.coupon.code,
          type: this.coupon.typeSelect.id,
          discountedValue: this.coupon.discountedValue,
          count: parseInt(this.coupon.count),
          fromDate: this.coupon.fromDate,
          toDate: this.coupon.toDate,
          onOff: this.coupon.onOff,
        };

        if(this.coupon.resortSelect) {
          body.resort = this.coupon.resortSelect.id
        }

        if (this.action) {
          this.action === "add" ?
            this.create(this.$Coupons, body, this.$t("messages.couponCreated"), null, this.success, this.error) :
            this.update(this.$Coupons, this.coupon.id, body, this.$t("messages.couponUpdated"), null, null, this.error);
            this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/coupons`);
    },
    error() {
      this.$emit("clearAction");
    }
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
